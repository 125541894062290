import React from "react";
import { initCart, saveCart } from "../utils/utils";

export interface CartProduct {
	sku: string;
	quantity: number;
	price1: string;
	price2?: string;
	name: string;
	image: string;
	variationName?: string;
}
interface CartContext {
	cart: CartProduct[];
	addProductToCart: (product: CartProduct) => void;
	removeProductFromCart: (productId: string) => void;
	updateProductQuantity: (productId: string, quantity: number) => void;
	clearCart: () => void;
}

const CartContext = React.createContext<CartContext | null>(null);

export const CartProvider: React.FC<{
	children: React.ReactNode;
	storeId: string;
}> = ({ children, storeId }) => {
	const [cart, setCart] = React.useState<CartProduct[]>([]);
	const [updating, setUpdating] = React.useState(false);

	const addProductToCart = (newProduct: CartProduct) => {
		setCart(prevState => {
			const existingProduct = prevState.find(
				product => product.sku === newProduct.sku
			);

			if (existingProduct) {
				return prevState.map(product =>
					product.sku === newProduct.sku
						? { ...product, quantity: product.quantity + newProduct.quantity }
						: product
				);
			} else {
				return [...prevState, newProduct];
			}
		});
	};

	const updateProductQuantity = (sku: string, quantity: number) => {
		setCart(prevState =>
			prevState.map(product =>
				product.sku === sku ? { ...product, quantity } : product
			)
		);
	};

	const removeProductFromCart = (sku: string) => {
		setCart(prevCart => prevCart.filter(product => product.sku !== sku));
	};

	const clearCart = () => {
		setCart([]);
	};

	React.useEffect(() => {
		const startCart = () => {
			const savedCart = initCart(storeId);
			setCart(savedCart);
		};
		startCart();
	}, []);

	React.useEffect(() => {
		saveCart(storeId, cart);
	}, [cart]);

	React.useEffect(() => {
		if (updating) {
			window.parent.postMessage(
				{ action: "addAllProductsLanding", product: cart },
				"" + process.env.REACT_APP_BASE_URL_LANDING
			);
		} else {
			setUpdating(true);
		}
	}, [cart]);

	React.useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
			const origin = event.origin as string;
			if (origin === process.env.REACT_APP_BASE_URL_LANDING) {
				if (event.data.action === "addAllProductsPlayer" && event.source) {
					setCart(event.data.product);
					setUpdating(false);
				}
			}
		};

		window.addEventListener("message", handleMessage);

		return () => {
			window.removeEventListener("message", handleMessage);
		};
	}, []);

	return (
		<CartContext.Provider
			value={{
				cart,
				addProductToCart,
				removeProductFromCart,
				updateProductQuantity,
				clearCart,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export const useCart = () => {
	const context = React.useContext(CartContext);
	if (!context) {
		throw new Error("useCart debe ser utilizado dentro de un CartProvider");
	}
	return context;
};
