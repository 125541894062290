import axios from "axios";
import { Dispatch } from "react";

import {
	URL_GET_PRODUCTS,
	URL_UPDATE_BROADCAST_CONNECTION,
	URL_UPDATE_SUMMARY,
	FIRA_URL_RECORDING,
	URL_RETRIEVE_ALL_FIRA_TV,
} from "./constants";
import { isDesktop } from "./isDesktop";
import {
	Broadcast,
	DataAction,
	QueueData,
	RecordingType,
	ShoppingCartRequestBody,
} from "./types";
import { makeApiCall } from "../services/apiCall";

const apiServiceUrl = process.env.REACT_APP_BASE_URL_SERVICE || "";

const api = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_MANAGEMENT,
});

const apiActions = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_ACTIONS,
});

const apiService = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_SERVICE,
});

const apiBusiness = axios.create({
	baseURL: process.env.REACT_APP_BASE_URL_BUSINESS,
});

const addConnToLS = (queuesData: any, fira_src: string) => {
	const connectionsLS = localStorage.getItem("FIRA_CONNECTIONS") || "[]";
	const connections = JSON.parse(connectionsLS);
	connections.push({
		queuesData,
		fira_src,
	});

	localStorage.setItem("FIRA_CONNECTIONS", JSON.stringify(connections));
};

const updateQuesuesData = (queuesData: any, fira_src: string) => {
	const connectionsLS = localStorage.getItem("FIRA_CONNECTIONS") || "[]";
	const connections = JSON.parse(connectionsLS);
	const index = connections.findIndex(
		(conn: any) => conn.fira_src === fira_src
	);
	if (index !== -1) {
		connections[index].queuesData.queues = queuesData;
		localStorage.setItem("FIRA_CONNECTIONS", JSON.stringify(connections));
	}
};

export const createConnection = async (
	fira_src: string,
	userGeneratedId: string,
	parentId: string
) => {
	const connectionsLS = localStorage.getItem("FIRA_CONNECTIONS");

	if (connectionsLS) {
		const connections = JSON.parse(connectionsLS);
		const connection = connections.find(
			(conn: any) => conn.fira_src === fira_src
		);

		if (connection) {
			return connection.queuesData;
		}
	}

	try {
		const data = {
			userName: "",
			broadcastingId: fira_src,
			playerConnectionId: userGeneratedId,
			connectionType: isDesktop() ? "WEB" : "MOBILE",
			parentId: parentId,
		};

		const res = await makeApiCall<QueueData>(apiActions, {
			url: "/v1/live-broadcasting-actions/connection/new",
			method: "POST",
			data,
		});

		addConnToLS(res, fira_src);
		return res;
	} catch (error: any) {
		return error.response;
	}
};

export const updateConnection: any = async (
	firasrc: string,
	connectionId: string
) => {
	try {
		const res = await apiActions.put(
			`${URL_UPDATE_BROADCAST_CONNECTION}${firasrc}/${connectionId}`
		);
		if (res.status == 201) {
			updateQuesuesData(res.data, firasrc);
			return res;
		} else {
			return updateConnection(firasrc, connectionId);
		}
	} catch (error: any) {
		console.log(error);
		return error.response;
	}
};

export const getCurrentSummary: any = async (liveBroadcastingId: string) => {
	try {
		const res = await apiActions.get(
			`${URL_UPDATE_SUMMARY}${liveBroadcastingId}`
		);
		if (res.status == 200) {
			return res.data;
		} else {
			return getCurrentSummary(liveBroadcastingId);
		}
	} catch (error: any) {
		console.log(error);
		return error.response;
	}
};

export enum ProductClickType {
	gallery = "regular-click",
	popup = "popup-click",
	galleryIntention = "regular-intention-click",
	popupIntention = "popup-intention-click",
}
export const doClickToProduct = (
	playerConnectionId: string,
	productInfo: any,
	broadcastId: string,
	connectionId: string,
	startTime: string,
	type: ProductClickType,
	parentId: string
) => {
	const { product, id } = productInfo;
	const { price1, price2, price3, directUrl } = product;

	const price = price1 > 0 ? price1 : price2 > 0 ? price2 : price3;
	const priceText = price1 > 0 ? "price1" : price2 > 0 ? "price2" : "price3";

	const data = {
		playerConnectionId,
		broadcastId,
		connectionId,
		broadcastingProductId: id,
		quantity: 0,
		actionPriceTaken: priceText,
		price: {
			amount: price,
			taxRate: 0,
			bidRate: 0,
			active: true,
		},
		actionUrl: directUrl,
		startTime,
		parentId,
	};

	return apiActions.post(`/v1/live-broadcasting-actions/${type}/new`, data);
};

export const regularClickVOD = async (
	productInfo: any,
	videoConnectionId: string,
	videoId: string,
	storeId: string,
	currency: string,
	elapsedTime: number
) => {
	const { product, id } = productInfo;
	const { price1, price2, price3 } = product;

	const price = price1 > 0 ? price1 : price2 > 0 ? price2 : price3;
	const priceText = price1 > 0 ? "price1" : price2 > 0 ? "price2" : "price3";

	// transform elapsed time to miliseconds
	elapsedTime = elapsedTime * 1000;

	const data = {
		videoConnectionId,
		videoId,
		storeId,
		videoProductId: id,
		elapsedTime,
		productPriceActioned: {
			description: priceText,
			amount: price,
			currency: currency,
			discountRate: 0.0,
		},
	};

	const res = await apiActions.post(
		"/v1/video-actions/product/click/add",
		data
	);

	if (res.status === 201) {
		console.log("Regular click VOD sent");
	} else {
		console.log("Error sending regular click");
	}
};

export const sendPlayVOD = async (
	videoId: string,
	videoConnectionId: string,
	storeId: string,
	actionType: "PLAY" | "PAUSE",
	actionElapseAt: number
) => {
	// transform elapsed time to miliseconds
	actionElapseAt = actionElapseAt * 1000;

	const data = {
		videoId,
		videoConnectionId,
		storeId,
		actionType,
		actionElapseAt,
	};

	const res = await apiActions.post("/v1/video-actions/play/add", data);

	if (res.status === 201) {
		console.log("Play VOD sent");
	} else {
		console.log("Error sending play VOD");
	}
};

export const sendLike = async (
	playerConnectionId: string,
	broadcastId: string,
	connectionId: string,
	startTime: string,
	parentId?: string
) => {
	const res = await apiActions.put("/v1/live-broadcasting-actions/like", {
		playerConnectionId,
		broadcastId,
		connectionId,
		startTime,
		parentId,
	});
	if (res.status == 201) {
		console.log("Like sent");
	} else {
		console.error("Error sending like");
	}
};

export const sendLikeVOD = async (
	videoId: string,
	videoConnectionId: string,
	storeId: string,
	elapsedTime: number
) => {
	// transform elapsed time to miliseconds
	elapsedTime = elapsedTime * 1000;

	const data = {
		videoId,
		videoConnectionId,
		storeId,
		elapsedTime,
	};
	const res = await apiActions.post("/v1/video-actions/like/add", data);
	if (res.status == 201) {
		console.log("Like sent");
	} else {
		console.error("Error sending like");
	}
};

export const sendShare = async (
	playerConnectionId: string,
	broadcastId: string,
	connectionId: string,
	startTime: string,
	parentId?: string
) => {
	const body = {
		playerConnectionId,
		broadcastId,
		connectionId,
		startTime,
		parentId,
	};
	const res = await apiActions.put("/v1/live-broadcasting-actions/share", body);

	if (res.status == 201) {
		console.log("Share sent");
	} else {
		console.error("Error sending share");
	}
};

export const sendShareVOD = async (
	urlShare: string,
	toWhom: string,
	socialMediaType: string,
	storeId: string,
	videoConnectionId: string,
	videoId: string,
	elapsedTime: number
) => {
	// transform elapsed time to miliseconds
	elapsedTime = elapsedTime * 1000;

	const data = {
		videoId,
		videoConnectionId,
		storeId,
		elapsedTime,
		detail: {
			toWhom,
			urlShare,
			socialMediaType,
		},
	};
	const res = await apiActions.post("/v1/video-actions/share/add", data);
	if (res.status == 201) {
		console.log("Share sent");
	} else {
		console.error("Error sending share");
	}
};

export const sendShoppingCart = async (
	broadcastId: string,
	broadcastingProductPurchase: ShoppingCartRequestBody[],
	connectionId: string,
	whatsappName: string
) => {
	try {
		const body = {
			liveBroadcastingId: broadcastId,
			broadcastingProductPurchase,
			connectionId,
			whatsappName,
		};
		await apiBusiness.post(
			"/v1/fira-broadcasting-purchase/shopping-cart",
			body
		);
	} catch (error) {
		console.warn(error);
	}
};

export const getLiveData = async (fira_src: string, url: string) => {
	return api.get<Broadcast>(
		`api/v1/fira-broadcast-event-controller/live-data/${fira_src}`,
		{
			headers: {
				"fira-referrer": url,
			},
		}
	);
};

export const getAdditionalFields = (fira_key: string) => {
	return apiBusiness.get<{ [key: string]: string }>(
		`/v1/fira-business/internal/store/additional-fields/${fira_key}`
	);
};

export const saveUserData = async (data: any) => {
	return await api.post(
		"/api/v1/fira-broadcast-event-controller/save-chat-user-data",
		data
	);
};

export const createAnonGetStreamUser = async (firaSrc: string) => {
	return await api.get(
		`api/v1/fira-broadcast-event-controller/anon-user/${firaSrc}`,
		{
			headers: {
				"Content-Type": "application/json",
				"Access-Control-Allow-Origin": "*",
			},
		}
	);
};

export const getVideoData = async (fira_src: string) => {
	const url = `${URL_RETRIEVE_ALL_FIRA_TV}${fira_src}`;
	const res = await apiBusiness.get(url);
	return res.data;
};

export const getProducts = async (
	fira_src: string,
	dispatch: Dispatch<DataAction>
) => {
	try {
		const url = `${apiServiceUrl}${URL_GET_PRODUCTS}${fira_src}`;
		const eventSource = new EventSource(url);
		eventSource.onmessage = event => {
			dispatch({
				type: "@ADD_PRODUCT",
				payload: { product: JSON.parse(event.data) },
			});
		};
		eventSource.onerror = () => {
			eventSource.close();
		};

		return [];
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const findVideo = (
	videoList: RecordingType[],
	type: "MP4" | "HLS" = "MP4"
): RecordingType => {
	const videoFile = videoList.find(file => file.videoFormat === type);

	if (!videoFile) {
		return findVideo(videoList, "HLS");
	}

	return videoFile;
};

export const getUrlRecording = async (fira_src: string) => {
	try {
		const res = await api.get(`${FIRA_URL_RECORDING}${fira_src}`);
		if (res.status == 200) {
			return {
				data: { ...res.data },
				status: res.status,
			};
		} else {
			console.warn("Error getting recording");
			return { status: res.status };
		}
	} catch (error: any) {
		const status = error.response.status;
		console.warn("Error getting recording");
		return {
			status,
		};
	}
};

export const getGeoData = async () => {
	try {
		const res = await axios.get<{ ip: string }>(
			"https://api.ipify.org/?format=json"
		);
		return res.data;
	} catch (error) {
		console.warn("Error getting ip data", error);
		return {
			ip: "not_found",
		};
	}
};
